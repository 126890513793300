import { Link } from 'gatsby';
import "../scss/main.scss"
import React from 'react';
import Header from '../components/header';
import Main from '../components/main';
import PageHeader from '../components/Organism/PageHeader/PageHeader';
import Footer from '../components/Footer/Footer';
import OurServices from '../components/OurServices/OurServices'


function ThankYou(props) {
    return (
            <>
            <Header/>
            <Main>
                <PageHeader h1={"Ура! Ваши данные отправлены!"} 
                    p={"Мы свяжемся с вами в ближайшие 5-10 минут. Спасибо!"} 
                    >
                        <Link to="/">На главную</Link>
                    </PageHeader>
                    <OurServices col={3} cardsData={[
                        {
                            header:"Разработка Лендинга",
                            desc:"Создаём посадочные страницы так, чтобы пользователь совершил целевое действие",
                            link:"../landing-page/",
                        },
                        {
                            header:"Многостраничные сайты",
                            desc:"Разрабатываем корпоративные сайты и информационные порталы так, чтобы пользователь не потерялся на вашем сайте",
                            link:"#",
                        },
                        {
                            header:"Разработка интернет-магазина",
                            desc:"Создаем E-commerce приложения так чтобы вашим покупателям было удобно",
                            link:"#",
                        },
                        {
                            header:"Тех-поддержка сайтов",
                            desc:"Станем вашим надежным партнером по доработке сайтов",
                            link:"#",
                        },
                        {
                            header:"Контекстная реклама",
                            desc:"Создаём посадочные страницы так, чтобы пользователь совершил целевое действие",
                            link:"../context-advertising/",
                        },
                        {
                            header:"Таргетированная реклама",
                            desc:"Создаём посадочные страницы так, чтобы пользователь совершил целевое действие",
                            link:"#",
                        },
                
                    ]}>Наши услуги</OurServices>
            </Main>
            <Footer/>
        </>
        
    );
}

export default ThankYou;